var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ak-stacked-window",
        {
          attrs: { showClose: false },
          on: {
            attemptClose: function ($event) {
              return _vm.attemptClose($event)
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "ak-form",
            _vm._g(
              {
                attrs: {
                  bundle: _vm.bundle,
                  definition: _vm.definition,
                  objectId: _vm.objectId,
                  relationString: _vm.relationString,
                },
                on: {
                  save: _vm.goToGrid,
                  cancel: function ($event) {
                    return _vm.attemptClose(_vm.close)
                  },
                },
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }