import VueRouter from 'vue-router';
import Dashboard from './views/pages/Dashboard.vue';
import UiKit from './views/pages/UiKit.vue';
import FormPage from "./views/pages/FormPage.vue";
import Form from "./views/pages/Form.vue";

Vue.use(VueRouter);

let routes = [
    {
        path: '/',
        component: Dashboard,
        meta: {
            title: "dashboard",
        },
        alias: '/home'
    },
    {
        path: '/form/:bundle/:definition/:objectId?/:relationString?',
        name: 'formPage',
        component: FormPage,
        meta: {
            title: "Form"
        },
    },
    {
        path: '/ui-kit',
        component: UiKit,
        meta: {
            title: "UI kit",
        },
    },
];

routes = Vue.cmsRouter.setRoutes(routes);

const router = new VueRouter({
    routes
})

Vue.cmsRouter.setActiveRouter(router);

export default router;
