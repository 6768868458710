<template>
    <div>
        <ak-stacked-window
            :showClose="false"
            @attemptClose="attemptClose($event)"
            @close="close"
        >
            <ak-form
                :bundle="bundle"
                :definition="definition"
                :objectId="objectId"
                :relationString="relationString"
                @save="goToGrid"
                @cancel="attemptClose(close)"
                v-on="$listeners"
            />
        </ak-stacked-window>
    </div>
</template>

<script>
    export default {
        name: 'Form',
        data() {
            return {
                showConfirm: false,
            }
        },
        computed: {
            bundle() {
                return this.$route.params.bundle;
            },
            definition() {
                return this.$route.params.definition;
            },
            objectId() {
                return this.$route.params.objectId;
            },
            relationString() {
                return this.$route.params.relationString;
            }
        },
        methods: {
            goToGrid(data) {
                if(this.$route.name === 'blueprint-form') {
                    this.$router.push({ name: 'blueprint'});
                    this.$emit('save', data);
                    return;
                }

                this.$router.push({ name: 'grid' , params: {
                        bundle: data.bundleId,
                        gridDefinition: data.definitionId,
                }});
            },
            close() {
                this.$router.back();
            },
            attemptClose(close) {
                if (! this.alertUnsaved) {
                    close();
                    return;
                }

                this.showConfirm = true;
                this.confirmCallback = close;
            }
        }
    }
</script>
