var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ak-form", {
    key: _vm.bundle + _vm.definition + _vm.objectId,
    attrs: {
      bundle: _vm.bundle,
      definition: _vm.definition,
      objectId: _vm.objectId,
      relationString: _vm.relationString,
    },
    scopedSlots: _vm._u([
      {
        key: "submit-button",
        fn: function (ref) {
          var submit = ref.submit
          var form = ref.form
          var baseUriControls = ref.baseUriControls
          return [
            form.footerWidgets && form.footerWidgets.widgets.length
              ? _c("div", { staticClass: "ak-form__footer" }, [
                  _c(
                    "div",
                    { staticClass: "ak-button-group justify-content-end" },
                    _vm._l(form.footerWidgets.widgets, function (widget) {
                      return _c(
                        "ak-widget",
                        _vm._g(
                          {
                            key: widget.id,
                            attrs: {
                              slot: widget.slot,
                              type: widget.type,
                              callbacks: widget.callbacks,
                              contextType: form.footerWidgets.contextType,
                              callbackUri: baseUriControls,
                              attributes: Object.assign({}, widget.attributes, {
                                id: widget.id,
                                baseUri: baseUriControls,
                                relationString: _vm.relationString,
                                currentObjectId: _vm.objectId,
                              }),
                            },
                            on: {
                              submit: submit,
                              "widget-event-callback-loading": _vm.setLoading,
                            },
                            slot: widget.slot,
                          },
                          _vm.$listeners
                        )
                      )
                    }),
                    1
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }