<template>
    <ak-form
            :bundle="bundle"
            :definition="definition"
            :objectId="objectId"
            :relationString="relationString"
            :key="bundle + definition + objectId"
    >

        <template v-slot:submit-button="{submit, form, baseUriControls}">
            <div v-if="form.footerWidgets && form.footerWidgets.widgets.length" class="ak-form__footer">
                <div class="ak-button-group justify-content-end">
                    <ak-widget
                            v-for="widget in form.footerWidgets.widgets"
                            :key="widget.id"
                            :type="widget.type"
                            :callbacks="widget.callbacks"
                            :slot="widget.slot"
                            :contextType="form.footerWidgets.contextType"
                            :callbackUri="baseUriControls"
                            :attributes="{ ...widget.attributes, id: widget.id, baseUri: baseUriControls, relationString: relationString, currentObjectId: objectId}"
                            @submit="submit"
                            @widget-event-callback-loading="setLoading"
                            v-on="$listeners"
                    />
                </div>
            </div>
        </template>
    </ak-form>
</template>

<script>

export default {
    name: 'FormPage',
    computed: {
        bundle() {
            return this.$route.params.bundle;
        },
        definition() {
            return this.$route.params.definition;
        },
        objectId() {
            return this.$route.params.objectId ?
                    this.$route.params.objectId :
                    'new';
        },
        relationString() {
            return this.$route.params.relationString;
        }
    },
    methods: {
        goToGrid(data) {
            this.$router.push({
                name: 'grid', params: {
                    bundle: data.bundleId,
                    gridDefinition: data.definitionId,
                }
            });
        },
    }
}
</script>
